import { GraphQLClient } from 'graphql-request';
import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { domain, sleep } from 'common/utils';

export const client = new GraphQLClient(`${domain.dashboard}/api/gql`, {
  credentials: 'include',
  mode: 'cors',
});

// client.setHeader('Access-Control-Allow-Origin', '*');

export const api = createApi({
  baseQuery: retry(graphqlRequestBaseQuery({ client }), {
    maxRetries: 3,
    backoff: async (attempt, maxRetries) => {
      await sleep(3000);
      return;
    },
  }),
  endpoints: () => ({}),
});
