import { api as generatedApi } from 'app/api/generated';

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['PROGRAM'],
  endpoints: {
    GetProgram: {
      providesTags: (result, error, arg) => (result?.program ? [{ ...result.program, type: 'PROGRAM' }] : ['PROGRAM']),
    },
    UpdateProgram: {
      invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.id }],
      extraOptions: { maxRetries: 0 },
    },
    DeleteProgram: {
      invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.id }],
      extraOptions: { maxRetries: 0 },
    },
    ActivateProgram: {
      invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.id }],
      extraOptions: { maxRetries: 0 },
    },
    CreateRisingStarActivity: {
      invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.programId }],
      extraOptions: { maxRetries: 0 },
    },
    CreateInfoActivity: {
      invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.programId }],
      extraOptions: { maxRetries: 0 },
    },
    CreatePollActivity: {
      invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.programId }],
      extraOptions: { maxRetries: 0 },
    },
    CreateYesNoActivity: {
      invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.programId }],
      extraOptions: { maxRetries: 0 },
    },
    UpdateRisingStarActivity: {
      invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.programId }],
      extraOptions: { maxRetries: 0 },
    },
    UpdateInfoActivity: {
      invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.programId }],
      extraOptions: { maxRetries: 0 },
    },
    UpdatePollActivity: {
      invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.programId }],
      extraOptions: { maxRetries: 0 },
    },
    UpdateYesNoActivity: {
      invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.programId }],
      extraOptions: { maxRetries: 0 },
    },
    UpdateActivityStage: { invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.programId }] },
    DeleteActivity: { invalidatesTags: (result, error, arg) => [{ type: 'PROGRAM', id: arg.programId }] },
    GetProgramStats: { extraOptions: { maxRetries: 0 } },
    GetMe: { extraOptions: { maxRetries: 0 } },
    Login: { extraOptions: { maxRetries: 0 } },
  },
});

export const {
  useGetProgramQuery,
  useLazyGetProgramQuery,
  useCreateProgramMutation,
  useUpdateProgramMutation,
  useDeleteProgramMutation,
  useActivateProgramMutation,
  useCreateRisingStarActivityMutation,
  useUpdateRisingStarActivityMutation,
  useCreateInfoActivityMutation,
  useUpdateInfoActivityMutation,
  useCreatePollActivityMutation,
  useUpdatePollActivityMutation,
  useGetProgramsQuery,
  useLazyGetProgramsQuery,
  useGetMeQuery,
  useLazyGetMeQuery,
  useLoginMutation,
  useUpdateActivitiesOrderMutation,
  useCreatePreSignedUrlMutation,
  useUpdateActivityStageMutation,
  useGetProgramLiveUsersCountQuery,
  useDuplicateProgramMutation,
  useGetProgramStatsQuery,
  useCreateYesNoActivityMutation,
  useUpdateYesNoActivityMutation,
  useDeleteActivityMutation,
  useCreateProgramWinnersSheetMutation,
} = generatedApi;
