import { api } from 'app/api/baseApi';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONObject: any;
};

export type Activity = InfoActivity | PollActivity | RisingStarActivity | YesNoActivity;

export enum ActivityStages {
  Hide = 'Hide',
  Show = 'Show',
  StartCheckIn = 'StartCheckIn',
  StartVote = 'StartVote',
  StopCheckIn = 'StopCheckIn',
  StopVote = 'StopVote',
}

export enum ActivityTypes {
  Info = 'Info',
  Poll = 'Poll',
  RisingStar = 'RisingStar',
  YesNo = 'YesNo',
}

export enum AuthStatus {
  Authenticated = 'Authenticated',
  Failed = 'Failed',
}

export type CreatePresignedUrlResponse = {
  __typename?: 'CreatePresignedUrlResponse';
  fields: Scalars['JSONObject'];
  mimetype: Scalars['String'];
  url: Scalars['String'];
};

export type CreateProgramRequest = {
  name: Scalars['String'];
  registration?: InputMaybe<ProgramRegistrationInput>;
  script?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<ProgramThemeInput>;
  tos?: InputMaybe<ProgramTosInput>;
};

export type CreateProgramWinnersSheet = {
  __typename?: 'CreateProgramWinnersSheet';
  url: Scalars['String'];
};

export type FailedAuthResponse = {
  __typename?: 'FailedAuthResponse';
  reason: Scalars['String'];
};

export type GetMeResponse = {
  __typename?: 'GetMeResponse';
  email: Scalars['String'];
  id: Scalars['String'];
};

export type GetProgramsRequest = {
  active: Scalars['Boolean'];
  direction?: InputMaybe<OrderDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['String']>;
};

export type GetProgramsResponse = {
  __typename?: 'GetProgramsResponse';
  programs: Array<Program>;
  total: Scalars['Int'];
};

export type InfoActivity = {
  __typename?: 'InfoActivity';
  animationUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mediaPlacement?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  stage: ActivityStages;
  title: Scalars['String'];
  type: ActivityTypes;
};

export type InfoActivityInput = {
  animationUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionLink?: InputMaybe<Scalars['String']>;
  mediaPlacement?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  title: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  failed?: Maybe<FailedAuthResponse>;
  status: AuthStatus;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateProgram?: Maybe<Program>;
  createInfoActivity?: Maybe<Scalars['String']>;
  createPollActivity?: Maybe<Scalars['String']>;
  createProgram: Scalars['String'];
  createProgramWinnersSheet: CreateProgramWinnersSheet;
  createRisingStarActivity?: Maybe<Scalars['String']>;
  createYesNoActivity?: Maybe<Scalars['String']>;
  deleteActivity?: Maybe<Scalars['String']>;
  deleteProgram?: Maybe<Program>;
  duplicateProgram: Scalars['String'];
  login: LoginResponse;
  updateActivitiesOrder: Array<Activity>;
  updateActivityStage: Activity;
  updateInfoActivity?: Maybe<InfoActivity>;
  updatePollActivity?: Maybe<PollActivity>;
  updateProgram?: Maybe<Program>;
  updateRisingStarActivity?: Maybe<RisingStarActivity>;
  updateYesNoActivity?: Maybe<YesNoActivity>;
  uploadProgramFile: CreatePresignedUrlResponse;
};

export type MutationActivateProgramArgs = {
  id: Scalars['String'];
};

export type MutationCreateInfoActivityArgs = {
  activity: InfoActivityInput;
  programId: Scalars['String'];
};

export type MutationCreatePollActivityArgs = {
  activity: PollActivityInput;
  programId: Scalars['String'];
};

export type MutationCreateProgramArgs = {
  program: CreateProgramRequest;
};

export type MutationCreateProgramWinnersSheetArgs = {
  programId: Scalars['String'];
};

export type MutationCreateRisingStarActivityArgs = {
  activity: RisingStarActivityInput;
  programId: Scalars['String'];
};

export type MutationCreateYesNoActivityArgs = {
  activity: YesNoActivityInput;
  programId: Scalars['String'];
};

export type MutationDeleteActivityArgs = {
  activityId: Scalars['String'];
  programId: Scalars['String'];
};

export type MutationDeleteProgramArgs = {
  id: Scalars['String'];
};

export type MutationDuplicateProgramArgs = {
  id: Scalars['String'];
};

export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationUpdateActivitiesOrderArgs = {
  id: Scalars['String'];
  order: Array<Scalars['String']>;
};

export type MutationUpdateActivityStageArgs = {
  activityId: Scalars['String'];
  programId: Scalars['String'];
  stage: ActivityStages;
};

export type MutationUpdateInfoActivityArgs = {
  activity: InfoActivityInput;
  activityId: Scalars['String'];
  programId: Scalars['String'];
};

export type MutationUpdatePollActivityArgs = {
  activity: PollActivityInput;
  activityId: Scalars['String'];
  programId: Scalars['String'];
};

export type MutationUpdateProgramArgs = {
  id: Scalars['String'];
  program: CreateProgramRequest;
};

export type MutationUpdateRisingStarActivityArgs = {
  activity: RisingStarActivityInput;
  activityId: Scalars['String'];
  programId: Scalars['String'];
};

export type MutationUpdateYesNoActivityArgs = {
  activity: YesNoActivityInput;
  activityId: Scalars['String'];
  programId: Scalars['String'];
};

export type MutationUploadProgramFileArgs = {
  filename: Scalars['String'];
  id: Scalars['String'];
};

export enum OrderDirection {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export type PollActivity = {
  __typename?: 'PollActivity';
  answerType: PollAnswerType;
  answers: Array<PollActivityAnswer>;
  continue?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  numOfVotesAllowed?: Maybe<Scalars['Int']>;
  question: Scalars['String'];
  stage: ActivityStages;
  thankYou?: Maybe<Scalars['String']>;
  type: ActivityTypes;
  xmlVoteId?: Maybe<Scalars['String']>;
};

export type PollActivityAnswer = {
  __typename?: 'PollActivityAnswer';
  image: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  xmlAnswerId: Scalars['String'];
};

export type PollActivityAnswerInput = {
  image: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
  xmlAnswerId: Scalars['String'];
};

export type PollActivityInput = {
  answerType: PollAnswerType;
  answers: Array<PollActivityAnswerInput>;
  continue?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  numOfVotesAllowed?: InputMaybe<Scalars['Int']>;
  question: Scalars['String'];
  thankYou?: InputMaybe<Scalars['String']>;
  xmlVoteId?: InputMaybe<Scalars['String']>;
};

export enum PollAnswerType {
  Images = 'Images',
  TextAndImages = 'TextAndImages',
}

export type Program = {
  __typename?: 'Program';
  active: Scalars['Boolean'];
  activities?: Maybe<Array<Activity>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  registration?: Maybe<ProgramRegistration>;
  script?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  theme?: Maybe<ProgramTheme>;
  tos?: Maybe<ProgramTos>;
  updatedAt: Scalars['DateTime'];
  usersLiveCount: Scalars['Int'];
};

export type ProgramActivityStats = {
  __typename?: 'ProgramActivityStats';
  checkins: Scalars['Int'];
  id: Scalars['String'];
  views: Scalars['Int'];
  votes: Array<ProgramActivityVoteStats>;
};

export type ProgramActivityVoteStats = {
  __typename?: 'ProgramActivityVoteStats';
  answerId: Scalars['String'];
  total: Scalars['Int'];
};

export type ProgramRegistration = {
  __typename?: 'ProgramRegistration';
  active: Scalars['Boolean'];
  dismiss: Scalars['String'];
  fields: Array<ProgramRegistrationField>;
  pic?: Maybe<Scalars['String']>;
  showDismiss: Scalars['Boolean'];
  submit: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  type: RegistrationType;
};

export type ProgramRegistrationField = {
  __typename?: 'ProgramRegistrationField';
  active: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  name: RegistrationFieldType;
  required: Scalars['Boolean'];
};

export type ProgramRegistrationFieldInput = {
  active: Scalars['Boolean'];
  label?: InputMaybe<Scalars['String']>;
  name: RegistrationFieldType;
  required: Scalars['Boolean'];
};

export type ProgramRegistrationInput = {
  active: Scalars['Boolean'];
  dismiss: Scalars['String'];
  fields: Array<ProgramRegistrationFieldInput>;
  pic?: InputMaybe<Scalars['String']>;
  showDismiss: Scalars['Boolean'];
  submit: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  type: RegistrationType;
};

export type ProgramRegistrationStats = {
  __typename?: 'ProgramRegistrationStats';
  total: Scalars['Int'];
  type: RegistrationType;
};

export type ProgramStats = {
  __typename?: 'ProgramStats';
  activities: Array<ProgramActivityStats>;
  registrations: Array<ProgramRegistrationStats>;
  views: Scalars['Int'];
};

export type ProgramTos = {
  __typename?: 'ProgramTOS';
  active: Scalars['Boolean'];
  showCheckbox: Scalars['Boolean'];
  text: Scalars['String'];
  url: Scalars['String'];
};

export type ProgramTosInput = {
  active: Scalars['Boolean'];
  showCheckbox: Scalars['Boolean'];
  text: Scalars['String'];
  url: Scalars['String'];
};

export type ProgramTheme = {
  __typename?: 'ProgramTheme';
  bgColor?: Maybe<Scalars['String']>;
  bgImage?: Maybe<Scalars['String']>;
  btnBgColor?: Maybe<Scalars['String']>;
  btnColor?: Maybe<Scalars['String']>;
  dimmedColor?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  logoPlacement?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
};

export type ProgramThemeInput = {
  bgColor?: InputMaybe<Scalars['String']>;
  bgImage?: InputMaybe<Scalars['String']>;
  btnBgColor?: InputMaybe<Scalars['String']>;
  btnColor?: InputMaybe<Scalars['String']>;
  dimmedColor?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoPlacement?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getMe: GetMeResponse;
  getProgram?: Maybe<Program>;
  getProgramStats?: Maybe<ProgramStats>;
  getPrograms?: Maybe<GetProgramsResponse>;
};

export type QueryGetProgramArgs = {
  id: Scalars['String'];
};

export type QueryGetProgramStatsArgs = {
  id: Scalars['String'];
};

export type QueryGetProgramsArgs = {
  input: GetProgramsRequest;
};

export enum RegistrationFieldType {
  CheckboxOne = 'CheckboxOne',
  CheckboxTwo = 'CheckboxTwo',
  CustomTextFieldOne = 'CustomTextFieldOne',
  Email = 'Email',
  FullName = 'FullName',
  Phone = 'Phone',
}

export enum RegistrationType {
  Form = 'Form',
  FormAndNative = 'FormAndNative',
  Native = 'Native',
}

export type RisingStarActivity = {
  __typename?: 'RisingStarActivity';
  continue?: Maybe<Scalars['String']>;
  disqualifiedText: Scalars['String'];
  finalText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  qualifiedText: Scalars['String'];
  questionText: Scalars['String'];
  showFlare?: Maybe<Scalars['Boolean']>;
  singerImage: Scalars['String'];
  singerName: Scalars['String'];
  songName: Scalars['String'];
  stage: ActivityStages;
  thankYou?: Maybe<Scalars['String']>;
  type: ActivityTypes;
  xmlVoteId?: Maybe<Scalars['String']>;
};

export type RisingStarActivityInput = {
  continue?: InputMaybe<Scalars['String']>;
  disqualifiedText: Scalars['String'];
  finalText?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  qualifiedText: Scalars['String'];
  questionText: Scalars['String'];
  showFlare?: InputMaybe<Scalars['Boolean']>;
  singerImage: Scalars['String'];
  singerName: Scalars['String'];
  songName: Scalars['String'];
  thankYou?: InputMaybe<Scalars['String']>;
  xmlVoteId?: InputMaybe<Scalars['String']>;
};

export type YesNoActivity = {
  __typename?: 'YesNoActivity';
  continue?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mainColor: Scalars['String'];
  mainImage: Scalars['String'];
  name: Scalars['String'];
  noBgColor: Scalars['String'];
  noIcon?: Maybe<Scalars['String']>;
  noText: Scalars['String'];
  questionText: Scalars['String'];
  showFlare?: Maybe<Scalars['Boolean']>;
  stage: ActivityStages;
  thankYou?: Maybe<Scalars['String']>;
  type: ActivityTypes;
  xmlVoteId?: Maybe<Scalars['String']>;
  yesBgColor: Scalars['String'];
  yesIcon?: Maybe<Scalars['String']>;
  yesText: Scalars['String'];
};

export type YesNoActivityInput = {
  continue?: InputMaybe<Scalars['String']>;
  mainColor: Scalars['String'];
  mainImage: Scalars['String'];
  name: Scalars['String'];
  noBgColor: Scalars['String'];
  noIcon?: InputMaybe<Scalars['String']>;
  noText: Scalars['String'];
  questionText: Scalars['String'];
  showFlare?: InputMaybe<Scalars['Boolean']>;
  thankYou?: InputMaybe<Scalars['String']>;
  xmlVoteId?: InputMaybe<Scalars['String']>;
  yesBgColor: Scalars['String'];
  yesIcon?: InputMaybe<Scalars['String']>;
  yesText: Scalars['String'];
};

export type CreateProgramWinnersSheetMutationVariables = Exact<{
  programId: Scalars['String'];
}>;

export type CreateProgramWinnersSheetMutation = {
  __typename?: 'Mutation';
  program: { __typename?: 'CreateProgramWinnersSheet'; url: string };
};

export type ProgramFieldsFragment = {
  __typename?: 'Program';
  id: string;
  name: string;
  active: boolean;
  createdAt: any;
  updatedAt: any;
  usersLiveCount: number;
  slug: string;
  script?: string | null;
  activities?: Array<
    | {
        __typename?: 'InfoActivity';
        id: string;
        name: string;
        type: ActivityTypes;
        stage: ActivityStages;
        title: string;
        description?: string | null;
        mediaUrl?: string | null;
        animationUrl?: string | null;
        descriptionLink?: string | null;
        mediaPlacement?: string | null;
      }
    | {
        __typename?: 'PollActivity';
        id: string;
        name: string;
        xmlVoteId?: string | null;
        question: string;
        type: ActivityTypes;
        stage: ActivityStages;
        answerType: PollAnswerType;
        numOfVotesAllowed?: number | null;
        thankYou?: string | null;
        continue?: string | null;
        answers: Array<{ __typename?: 'PollActivityAnswer'; xmlAnswerId: string; image: string; text?: string | null }>;
      }
    | {
        __typename?: 'RisingStarActivity';
        id: string;
        name: string;
        type: ActivityTypes;
        stage: ActivityStages;
        xmlVoteId?: string | null;
        singerName: string;
        singerImage: string;
        songName: string;
        finalText?: string | null;
        questionText: string;
        qualifiedText: string;
        disqualifiedText: string;
        showFlare?: boolean | null;
        thankYou?: string | null;
        continue?: string | null;
      }
    | {
        __typename?: 'YesNoActivity';
        id: string;
        mainColor: string;
        mainImage: string;
        name: string;
        noBgColor: string;
        noIcon?: string | null;
        noText: string;
        questionText: string;
        stage: ActivityStages;
        type: ActivityTypes;
        xmlVoteId?: string | null;
        yesBgColor: string;
        yesIcon?: string | null;
        yesText: string;
        showFlare?: boolean | null;
        thankYou?: string | null;
        continue?: string | null;
      }
  > | null;
  registration?: {
    __typename?: 'ProgramRegistration';
    active: boolean;
    dismiss: string;
    showDismiss: boolean;
    submit: string;
    subtitle: string;
    title: string;
    type: RegistrationType;
    pic?: string | null;
    fields: Array<{
      __typename?: 'ProgramRegistrationField';
      name: RegistrationFieldType;
      required: boolean;
      active: boolean;
      label?: string | null;
    }>;
  } | null;
  theme?: {
    __typename?: 'ProgramTheme';
    bgColor?: string | null;
    btnBgColor?: string | null;
    btnColor?: string | null;
    bgImage?: string | null;
    dimmedColor?: string | null;
    logo?: string | null;
    primaryColor?: string | null;
    logoPlacement?: string | null;
  } | null;
  tos?: { __typename?: 'ProgramTOS'; active: boolean; showCheckbox: boolean; text: string; url: string } | null;
};

export type GetProgramQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetProgramQuery = {
  __typename?: 'Query';
  program?: {
    __typename?: 'Program';
    id: string;
    name: string;
    active: boolean;
    createdAt: any;
    updatedAt: any;
    usersLiveCount: number;
    slug: string;
    script?: string | null;
    activities?: Array<
      | {
          __typename?: 'InfoActivity';
          id: string;
          name: string;
          type: ActivityTypes;
          stage: ActivityStages;
          title: string;
          description?: string | null;
          mediaUrl?: string | null;
          animationUrl?: string | null;
          descriptionLink?: string | null;
          mediaPlacement?: string | null;
        }
      | {
          __typename?: 'PollActivity';
          id: string;
          name: string;
          xmlVoteId?: string | null;
          question: string;
          type: ActivityTypes;
          stage: ActivityStages;
          answerType: PollAnswerType;
          numOfVotesAllowed?: number | null;
          thankYou?: string | null;
          continue?: string | null;
          answers: Array<{
            __typename?: 'PollActivityAnswer';
            xmlAnswerId: string;
            image: string;
            text?: string | null;
          }>;
        }
      | {
          __typename?: 'RisingStarActivity';
          id: string;
          name: string;
          type: ActivityTypes;
          stage: ActivityStages;
          xmlVoteId?: string | null;
          singerName: string;
          singerImage: string;
          songName: string;
          finalText?: string | null;
          questionText: string;
          qualifiedText: string;
          disqualifiedText: string;
          showFlare?: boolean | null;
          thankYou?: string | null;
          continue?: string | null;
        }
      | {
          __typename?: 'YesNoActivity';
          id: string;
          mainColor: string;
          mainImage: string;
          name: string;
          noBgColor: string;
          noIcon?: string | null;
          noText: string;
          questionText: string;
          stage: ActivityStages;
          type: ActivityTypes;
          xmlVoteId?: string | null;
          yesBgColor: string;
          yesIcon?: string | null;
          yesText: string;
          showFlare?: boolean | null;
          thankYou?: string | null;
          continue?: string | null;
        }
    > | null;
    registration?: {
      __typename?: 'ProgramRegistration';
      active: boolean;
      dismiss: string;
      showDismiss: boolean;
      submit: string;
      subtitle: string;
      title: string;
      type: RegistrationType;
      pic?: string | null;
      fields: Array<{
        __typename?: 'ProgramRegistrationField';
        name: RegistrationFieldType;
        required: boolean;
        active: boolean;
        label?: string | null;
      }>;
    } | null;
    theme?: {
      __typename?: 'ProgramTheme';
      bgColor?: string | null;
      btnBgColor?: string | null;
      btnColor?: string | null;
      bgImage?: string | null;
      dimmedColor?: string | null;
      logo?: string | null;
      primaryColor?: string | null;
      logoPlacement?: string | null;
    } | null;
    tos?: { __typename?: 'ProgramTOS'; active: boolean; showCheckbox: boolean; text: string; url: string } | null;
  } | null;
};

export type CreateProgramMutationVariables = Exact<{
  program: CreateProgramRequest;
}>;

export type CreateProgramMutation = { __typename?: 'Mutation'; id: string };

export type UpdateProgramMutationVariables = Exact<{
  id: Scalars['String'];
  program: CreateProgramRequest;
}>;

export type UpdateProgramMutation = {
  __typename?: 'Mutation';
  program?: { __typename?: 'Program'; id: string } | null;
};

export type DeleteProgramMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteProgramMutation = {
  __typename?: 'Mutation';
  program?: { __typename?: 'Program'; active: boolean } | null;
};

export type ActivateProgramMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type ActivateProgramMutation = {
  __typename?: 'Mutation';
  program?: { __typename?: 'Program'; active: boolean } | null;
};

export type CreateRisingStarActivityMutationVariables = Exact<{
  programId: Scalars['String'];
  activity: RisingStarActivityInput;
}>;

export type CreateRisingStarActivityMutation = { __typename?: 'Mutation'; activityId?: string | null };

export type UpdateRisingStarActivityMutationVariables = Exact<{
  programId: Scalars['String'];
  activityId: Scalars['String'];
  activity: RisingStarActivityInput;
}>;

export type UpdateRisingStarActivityMutation = {
  __typename?: 'Mutation';
  updateRisingStarActivity?: { __typename?: 'RisingStarActivity'; name: string } | null;
};

export type CreateInfoActivityMutationVariables = Exact<{
  programId: Scalars['String'];
  activity: InfoActivityInput;
}>;

export type CreateInfoActivityMutation = { __typename?: 'Mutation'; activityId?: string | null };

export type UpdateInfoActivityMutationVariables = Exact<{
  programId: Scalars['String'];
  activityId: Scalars['String'];
  activity: InfoActivityInput;
}>;

export type UpdateInfoActivityMutation = {
  __typename?: 'Mutation';
  updateInfoActivity?: { __typename?: 'InfoActivity'; name: string } | null;
};

export type CreatePollActivityMutationVariables = Exact<{
  programId: Scalars['String'];
  activity: PollActivityInput;
}>;

export type CreatePollActivityMutation = { __typename?: 'Mutation'; activityId?: string | null };

export type UpdatePollActivityMutationVariables = Exact<{
  programId: Scalars['String'];
  activityId: Scalars['String'];
  activity: PollActivityInput;
}>;

export type UpdatePollActivityMutation = {
  __typename?: 'Mutation';
  updatePollActivity?: { __typename?: 'PollActivity'; name: string } | null;
};

export type CreateYesNoActivityMutationVariables = Exact<{
  programId: Scalars['String'];
  activity: YesNoActivityInput;
}>;

export type CreateYesNoActivityMutation = { __typename?: 'Mutation'; activityId?: string | null };

export type UpdateYesNoActivityMutationVariables = Exact<{
  programId: Scalars['String'];
  activityId: Scalars['String'];
  activity: YesNoActivityInput;
}>;

export type UpdateYesNoActivityMutation = {
  __typename?: 'Mutation';
  updateYesNoActivity?: { __typename?: 'YesNoActivity'; name: string } | null;
};

export type DeleteActivityMutationVariables = Exact<{
  activityId: Scalars['String'];
  programId: Scalars['String'];
}>;

export type DeleteActivityMutation = { __typename?: 'Mutation'; activityId?: string | null };

export type UpdateActivitiesOrderMutationVariables = Exact<{
  id: Scalars['String'];
  order: Array<Scalars['String']> | Scalars['String'];
}>;

export type UpdateActivitiesOrderMutation = {
  __typename?: 'Mutation';
  updateActivitiesOrder: Array<
    | { __typename: 'InfoActivity' }
    | { __typename: 'PollActivity' }
    | { __typename: 'RisingStarActivity' }
    | { __typename: 'YesNoActivity' }
  >;
};

export type CreatePreSignedUrlMutationVariables = Exact<{
  filename: Scalars['String'];
  id: Scalars['String'];
}>;

export type CreatePreSignedUrlMutation = {
  __typename?: 'Mutation';
  uploadProgramFile: { __typename?: 'CreatePresignedUrlResponse'; fields: any; mimetype: string; url: string };
};

export type UpdateActivityStageMutationVariables = Exact<{
  activityId: Scalars['String'];
  programId: Scalars['String'];
  stage: ActivityStages;
}>;

export type UpdateActivityStageMutation = {
  __typename?: 'Mutation';
  updateActivityStage:
    | { __typename: 'InfoActivity' }
    | { __typename: 'PollActivity' }
    | { __typename: 'RisingStarActivity' }
    | { __typename: 'YesNoActivity' };
};

export type GetProgramLiveUsersCountQueryVariables = Exact<{
  programId: Scalars['String'];
}>;

export type GetProgramLiveUsersCountQuery = {
  __typename?: 'Query';
  getProgram?: { __typename?: 'Program'; usersLiveCount: number } | null;
};

export type DuplicateProgramMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DuplicateProgramMutation = { __typename?: 'Mutation'; id: string };

export type GetProgramStatsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetProgramStatsQuery = {
  __typename?: 'Query';
  getProgramStats?: {
    __typename?: 'ProgramStats';
    views: number;
    registrations: Array<{ __typename?: 'ProgramRegistrationStats'; type: RegistrationType; total: number }>;
    activities: Array<{
      __typename?: 'ProgramActivityStats';
      id: string;
      views: number;
      checkins: number;
      votes: Array<{ __typename?: 'ProgramActivityVoteStats'; answerId: string; total: number }>;
    }>;
  } | null;
};

export type GetProgramsQueryVariables = Exact<{
  input: GetProgramsRequest;
}>;

export type GetProgramsQuery = {
  __typename?: 'Query';
  getPrograms?: {
    __typename?: 'GetProgramsResponse';
    total: number;
    programs: Array<{ __typename?: 'Program'; name: string; id: string; updatedAt: any }>;
  } | null;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = { __typename?: 'Query'; getMe: { __typename: 'GetMeResponse'; email: string; id: string } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename: 'LoginResponse';
    status: AuthStatus;
    failed?: { __typename: 'FailedAuthResponse'; reason: string } | null;
  };
};

export const ProgramFieldsFragmentDoc = `
    fragment ProgramFields on Program {
  id
  name
  active
  createdAt
  updatedAt
  usersLiveCount
  slug
  script
  activities {
    ... on RisingStarActivity {
      id
      name
      type
      stage
      xmlVoteId
      singerName
      singerImage
      songName
      finalText
      questionText
      qualifiedText
      disqualifiedText
      showFlare
      thankYou
      continue
    }
    ... on InfoActivity {
      id
      name
      type
      stage
      title
      description
      mediaUrl
      animationUrl
      descriptionLink
      mediaPlacement
    }
    ... on PollActivity {
      id
      name
      xmlVoteId
      question
      type
      stage
      answerType
      answers {
        xmlAnswerId
        image
        text
      }
      numOfVotesAllowed
      thankYou
      continue
    }
    ... on YesNoActivity {
      id
      mainColor
      mainImage
      name
      noBgColor
      noIcon
      noText
      questionText
      stage
      type
      xmlVoteId
      yesBgColor
      yesIcon
      yesText
      showFlare
      thankYou
      continue
    }
  }
  registration {
    active
    dismiss
    fields {
      name
      required
      active
      label
    }
    showDismiss
    submit
    subtitle
    title
    type
    pic
  }
  theme {
    bgColor
    btnBgColor
    btnColor
    bgImage
    dimmedColor
    logo
    primaryColor
    logoPlacement
  }
  tos {
    active
    showCheckbox
    text
    url
  }
}
    `;
export const CreateProgramWinnersSheetDocument = `
    mutation CreateProgramWinnersSheet($programId: String!) {
  program: createProgramWinnersSheet(programId: $programId) {
    url
  }
}
    `;
export const GetProgramDocument = `
    query GetProgram($id: String!) {
  program: getProgram(id: $id) {
    ...ProgramFields
  }
}
    ${ProgramFieldsFragmentDoc}`;
export const CreateProgramDocument = `
    mutation CreateProgram($program: CreateProgramRequest!) {
  id: createProgram(program: $program)
}
    `;
export const UpdateProgramDocument = `
    mutation UpdateProgram($id: String!, $program: CreateProgramRequest!) {
  program: updateProgram(id: $id, program: $program) {
    id
  }
}
    `;
export const DeleteProgramDocument = `
    mutation DeleteProgram($id: String!) {
  program: deleteProgram(id: $id) {
    active
  }
}
    `;
export const ActivateProgramDocument = `
    mutation ActivateProgram($id: String!) {
  program: activateProgram(id: $id) {
    active
  }
}
    `;
export const CreateRisingStarActivityDocument = `
    mutation CreateRisingStarActivity($programId: String!, $activity: RisingStarActivityInput!) {
  activityId: createRisingStarActivity(programId: $programId, activity: $activity)
}
    `;
export const UpdateRisingStarActivityDocument = `
    mutation UpdateRisingStarActivity($programId: String!, $activityId: String!, $activity: RisingStarActivityInput!) {
  updateRisingStarActivity(
    programId: $programId
    activityId: $activityId
    activity: $activity
  ) {
    name
  }
}
    `;
export const CreateInfoActivityDocument = `
    mutation CreateInfoActivity($programId: String!, $activity: InfoActivityInput!) {
  activityId: createInfoActivity(programId: $programId, activity: $activity)
}
    `;
export const UpdateInfoActivityDocument = `
    mutation UpdateInfoActivity($programId: String!, $activityId: String!, $activity: InfoActivityInput!) {
  updateInfoActivity(
    programId: $programId
    activityId: $activityId
    activity: $activity
  ) {
    name
  }
}
    `;
export const CreatePollActivityDocument = `
    mutation CreatePollActivity($programId: String!, $activity: PollActivityInput!) {
  activityId: createPollActivity(programId: $programId, activity: $activity)
}
    `;
export const UpdatePollActivityDocument = `
    mutation UpdatePollActivity($programId: String!, $activityId: String!, $activity: PollActivityInput!) {
  updatePollActivity(
    programId: $programId
    activityId: $activityId
    activity: $activity
  ) {
    name
  }
}
    `;
export const CreateYesNoActivityDocument = `
    mutation CreateYesNoActivity($programId: String!, $activity: YesNoActivityInput!) {
  activityId: createYesNoActivity(programId: $programId, activity: $activity)
}
    `;
export const UpdateYesNoActivityDocument = `
    mutation UpdateYesNoActivity($programId: String!, $activityId: String!, $activity: YesNoActivityInput!) {
  updateYesNoActivity(
    programId: $programId
    activityId: $activityId
    activity: $activity
  ) {
    name
  }
}
    `;
export const DeleteActivityDocument = `
    mutation DeleteActivity($activityId: String!, $programId: String!) {
  activityId: deleteActivity(activityId: $activityId, programId: $programId)
}
    `;
export const UpdateActivitiesOrderDocument = `
    mutation UpdateActivitiesOrder($id: String!, $order: [String!]!) {
  updateActivitiesOrder(id: $id, order: $order) {
    __typename
  }
}
    `;
export const CreatePreSignedUrlDocument = `
    mutation CreatePreSignedUrl($filename: String!, $id: String!) {
  uploadProgramFile(filename: $filename, id: $id) {
    fields
    mimetype
    url
  }
}
    `;
export const UpdateActivityStageDocument = `
    mutation UpdateActivityStage($activityId: String!, $programId: String!, $stage: ActivityStages!) {
  updateActivityStage(
    activityId: $activityId
    programId: $programId
    stage: $stage
  ) {
    __typename
  }
}
    `;
export const GetProgramLiveUsersCountDocument = `
    query GetProgramLiveUsersCount($programId: String!) {
  getProgram(id: $programId) {
    usersLiveCount
  }
}
    `;
export const DuplicateProgramDocument = `
    mutation DuplicateProgram($id: String!) {
  id: duplicateProgram(id: $id)
}
    `;
export const GetProgramStatsDocument = `
    query GetProgramStats($id: String!) {
  getProgramStats(id: $id) {
    views
    registrations {
      type
      total
    }
    activities {
      id
      views
      checkins
      votes {
        answerId
        total
      }
    }
  }
}
    `;
export const GetProgramsDocument = `
    query GetPrograms($input: GetProgramsRequest!) {
  getPrograms(input: $input) {
    total
    programs {
      name
      id
      updatedAt
    }
  }
}
    `;
export const GetMeDocument = `
    query GetMe {
  getMe {
    __typename
    email
    id
  }
}
    `;
export const LoginDocument = `
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    __typename
    status
    failed {
      __typename
      reason
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    CreateProgramWinnersSheet: build.mutation<
      CreateProgramWinnersSheetMutation,
      CreateProgramWinnersSheetMutationVariables
    >({
      query: (variables) => ({ document: CreateProgramWinnersSheetDocument, variables }),
    }),
    GetProgram: build.query<GetProgramQuery, GetProgramQueryVariables>({
      query: (variables) => ({ document: GetProgramDocument, variables }),
    }),
    CreateProgram: build.mutation<CreateProgramMutation, CreateProgramMutationVariables>({
      query: (variables) => ({ document: CreateProgramDocument, variables }),
    }),
    UpdateProgram: build.mutation<UpdateProgramMutation, UpdateProgramMutationVariables>({
      query: (variables) => ({ document: UpdateProgramDocument, variables }),
    }),
    DeleteProgram: build.mutation<DeleteProgramMutation, DeleteProgramMutationVariables>({
      query: (variables) => ({ document: DeleteProgramDocument, variables }),
    }),
    ActivateProgram: build.mutation<ActivateProgramMutation, ActivateProgramMutationVariables>({
      query: (variables) => ({ document: ActivateProgramDocument, variables }),
    }),
    CreateRisingStarActivity: build.mutation<
      CreateRisingStarActivityMutation,
      CreateRisingStarActivityMutationVariables
    >({
      query: (variables) => ({ document: CreateRisingStarActivityDocument, variables }),
    }),
    UpdateRisingStarActivity: build.mutation<
      UpdateRisingStarActivityMutation,
      UpdateRisingStarActivityMutationVariables
    >({
      query: (variables) => ({ document: UpdateRisingStarActivityDocument, variables }),
    }),
    CreateInfoActivity: build.mutation<CreateInfoActivityMutation, CreateInfoActivityMutationVariables>({
      query: (variables) => ({ document: CreateInfoActivityDocument, variables }),
    }),
    UpdateInfoActivity: build.mutation<UpdateInfoActivityMutation, UpdateInfoActivityMutationVariables>({
      query: (variables) => ({ document: UpdateInfoActivityDocument, variables }),
    }),
    CreatePollActivity: build.mutation<CreatePollActivityMutation, CreatePollActivityMutationVariables>({
      query: (variables) => ({ document: CreatePollActivityDocument, variables }),
    }),
    UpdatePollActivity: build.mutation<UpdatePollActivityMutation, UpdatePollActivityMutationVariables>({
      query: (variables) => ({ document: UpdatePollActivityDocument, variables }),
    }),
    CreateYesNoActivity: build.mutation<CreateYesNoActivityMutation, CreateYesNoActivityMutationVariables>({
      query: (variables) => ({ document: CreateYesNoActivityDocument, variables }),
    }),
    UpdateYesNoActivity: build.mutation<UpdateYesNoActivityMutation, UpdateYesNoActivityMutationVariables>({
      query: (variables) => ({ document: UpdateYesNoActivityDocument, variables }),
    }),
    DeleteActivity: build.mutation<DeleteActivityMutation, DeleteActivityMutationVariables>({
      query: (variables) => ({ document: DeleteActivityDocument, variables }),
    }),
    UpdateActivitiesOrder: build.mutation<UpdateActivitiesOrderMutation, UpdateActivitiesOrderMutationVariables>({
      query: (variables) => ({ document: UpdateActivitiesOrderDocument, variables }),
    }),
    CreatePreSignedUrl: build.mutation<CreatePreSignedUrlMutation, CreatePreSignedUrlMutationVariables>({
      query: (variables) => ({ document: CreatePreSignedUrlDocument, variables }),
    }),
    UpdateActivityStage: build.mutation<UpdateActivityStageMutation, UpdateActivityStageMutationVariables>({
      query: (variables) => ({ document: UpdateActivityStageDocument, variables }),
    }),
    GetProgramLiveUsersCount: build.query<GetProgramLiveUsersCountQuery, GetProgramLiveUsersCountQueryVariables>({
      query: (variables) => ({ document: GetProgramLiveUsersCountDocument, variables }),
    }),
    DuplicateProgram: build.mutation<DuplicateProgramMutation, DuplicateProgramMutationVariables>({
      query: (variables) => ({ document: DuplicateProgramDocument, variables }),
    }),
    GetProgramStats: build.query<GetProgramStatsQuery, GetProgramStatsQueryVariables>({
      query: (variables) => ({ document: GetProgramStatsDocument, variables }),
    }),
    GetPrograms: build.query<GetProgramsQuery, GetProgramsQueryVariables>({
      query: (variables) => ({ document: GetProgramsDocument, variables }),
    }),
    GetMe: build.query<GetMeQuery, GetMeQueryVariables | void>({
      query: (variables) => ({ document: GetMeDocument, variables }),
    }),
    Login: build.mutation<LoginMutation, LoginMutationVariables>({
      query: (variables) => ({ document: LoginDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
